
  import Vue from 'vue'
  import Projects from '../components/projects.vue'

  export default Vue.extend({
    name: 'Home',

    components: {
      Projects,
    },
  })


import { Project } from "@/code/project";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "Projects",

  data: () => ({
    repos: null,
  }),

  async mounted() {
    return;
  },

  computed: {
    ...mapState(["session"]),
  },

  methods: {

    ...mapMutations({
      addProject: "ADD_PROJECT"
    }),

    loadProjects() {
      Project.loadProjects("vv", "bassamsaliba").then((r: string[]) => {
        r.forEach(async (p: string) => {
          console.log(p);
          await Project.fromGit(p).then((p: Project) => {
            this.addProject(p);
          });
        });
      });
    },

    newProject(): void {
      const p = new Project(`Project ${this.session.projects.length + 1}`, "");

      this.addProject(p);

      this.$router.push(`design/${p.id}`);
    },
  },
});
